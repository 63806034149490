<template>
  <b-card no-body title="Usuarios">
    <b-row>
      <b-col md="6" class="mb-1">
        <b-form-row>
          <b-button
            variant="primary"
            v-b-modal.modal-usuario
            @click="accion = 'registrar'"
            class="mr-1"
          >
            <feather-icon icon="PlusIcon" class="mr-50" />
            <span class="align-middle">Nuevo</span>
          </b-button>

          <!-- <b-button
          :variant="buttonStyle"
          @click="confirmar_toogle_2F"
          class="mr-1"
        >
          <feather-icon icon="PlusIcon" class="mr-50" />
          <span class="align-middle">{{ titulo2f }}</span>
        </b-button> -->
         <b-button
            @click="actualizarLista()"
            variant="secondary"
            class="btn-icon mr-1"
          >
            <feather-icon icon="RotateCcwIcon" />
          </b-button>
          <b-form-checkbox
            v-model="autenticacion_2F"
            value="1"
            unchecked-value="0"
            id="asiento-fiscal"
            switch
            @change="toogle2f"
            ><b>Autenticacion 2 Factores</b>
          </b-form-checkbox>

         
        </b-form-row>
      </b-col>
      <b-col md="6" class="mb-1">
        <b-form-group
          label=""
          label-align-sm="right"
          label-for="filterInput"
          class="mb-0"
        >
          <b-input-group>
            <b-form-input
              id="filterInput"
              v-model="filter"
              type="search"
              @keyup.enter="onFiltered()"
              placeholder="Buscar.."
            />
          </b-input-group>
        </b-form-group>
      </b-col>

      <b-col cols="12">
        <b-overlay :show="loading">
          <b-table
            class="sm"
            responsive="sm"
            ref="selectableTable"
            selectable
            select-mode="single"
            hover
            :small="true"
            :items="items"
            :fields="fields"
            @row-selected="onRowSelected"
            show-empty
            empty-text="No se encontraron registros coincidentes"
          >
            <template #cell(selected)="{ rowSelected }">
              <template v-if="rowSelected">
                <i class="feather icon-disc primary" />
              </template>

              <template v-else>
                <i class="feather icon-circle" />
              </template>
            </template>

            <template #cell(roles)="data">
              <b-badge v-if="data.value[0]" variant="primary">
                {{ data.value[0].name }}
              </b-badge>
            </template>

            <template #cell(estado)="data">
              <b-badge :variant="estado[1][data.value]">
                {{ estado[0][data.value] }}
              </b-badge>
            </template>

            <template #cell(accion)="data">
              <div class="text-nowrap" v-if="data.item.id != 1">
                <b-button
                  v-b-modal.modal-usuario
                  @click="abriComponente(data.item)"
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  v-b-tooltip.hover.bottom="'Editar'"
                  class="btn-icon mr-1"
                  size="sm"
                >
                  <feather-icon icon="EditIcon" />
                </b-button>

                <b-button
                  @click="confirmar_eliminar(data.item.id)"
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-danger"
                  v-b-tooltip.hover.bottom="'Eliminar'"
                  class="btn-icon mr-1"
                  size="sm"
                >
                  <feather-icon icon="TrashIcon" />
                </b-button>

                <b-button
                  @click="confirmar_inactivar(data.item.id)"
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-danger"
                  v-b-tooltip.hover.bottom="'Inactivar usuario'"
                  class="btn-icon mr-1"
                  size="sm"
                >
                  <feather-icon icon="UserXIcon" />
                </b-button>

                <b-button
                  @click="confirmar_password_default(data.item.id)"
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-danger"
                  v-b-tooltip.hover.bottom="'Restablecer contraseña'"
                  class="btn-icon"
                  size="sm"
                >
                  <feather-icon icon="LockIcon" />
                </b-button>
              </div>
            </template>

            <template #cell()="data">
              <span class="text-nowrap">{{ data.value }}</span>
            </template>
          </b-table>
        </b-overlay>
      </b-col>
      <b-col cols="12">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="right"
          size="sm"
          class="my-0"
        />
      </b-col>
    </b-row>
    <UsuarioNuevoEditar
      :accion="accion"
      :item="item"
      @cerrarComponente="cerrarComponente"
      @actualizarLista="actualizarLista"
    ></UsuarioNuevoEditar>
  </b-card>
</template>

<script>
import axiosIns from "@/libs/axios";
import {
  BCard,
  BCardText,
  BTable,
  BBadge,
  BRow,
  BCol,
  BFormGroup,
  BFormRow,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BDropdown,
  BDropdownItem,
  BOverlay,
  BFormCheckbox,
  BContainer,
  VBTooltip,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import UsuarioNuevoEditar from "@/views/configuracion/usuarios/UsuarioNuevoEditar.vue";
//import axios from 'axios'
export default {
  components: {
    BFormCheckbox,
    BCard,
    BCardText,
    BTable,
    BBadge,
    BFormRow,
    BRow,
    BCol,
    BContainer,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BDropdown,
    BDropdownItem,
    UsuarioNuevoEditar,
    BOverlay,
  },
  directives: {
    Ripple,
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      items: [],
      fields: [
        { key: "name", label: "Nombre completo", sortable: false },
        { key: "email", label: "Email", sortable: false },
        { key: "roles", label: "Rol", sortable: false },
        { key: "estado", label: "Estado", sortable: false },
        "accion",
      ],
      selected: [],
      filter: "",
      filterOn: [],
      perPage: 10,
      pageOptions: [10, 25, 50],
      totalRows: 1,
      currentPage: 1,
      accion: "",
      loading: false,
      item: [],
      estado: [
        {
          1: "Activo",
          0: "Inactivo",
        },
        {
          1: "light-primary",
          0: "light-danger",
        },
      ],
      idopcion: -1,
      autenticacion_2F: "",
      password: "fiscalia123",
    };
  },
  created() {
    this.loading = true;
    this.listar();
    const api = this.mixing.opcions.find(
      (g) => g.nombre === "autenticacion_2F"
    );
    this.idopcion = api.id;
    this.getOpcion2F();
  },
  computed: {
    titulo2f() {
      return this.autenticacion_2F === "1" ? "Desactivar 2F" : "Activar 2F";
    },
    buttonStyle() {
      return this.autenticacion_2F === "1" ? "danger" : "primary";
    },
  },
  watch: {
    currentPage: function () {
      if (!this.loading) {
        this.loading = true;
        this.listar();
      }
    },
    filter: function (val) {
      if (val == "" || val.length == 0) {
        this.loading = true;
        this.listar();
      }
    },
  },
  methods: {
    getOpcion2F() {
      axiosIns.get(`/opciones/${this.idopcion}`).then((response) => {
        this.autenticacion_2F = response.data.data.valor;
      });
    },
    onRowSelected(items) {
      this.selected = items;
    },
    selectAllRows() {
      this.$refs.selectableTable.selectAllRows();
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected();
    },
    selectThirdRow() {
      this.$refs.selectableTable.selectRow(2);
    },
    unselectThirdRow() {
      this.$refs.selectableTable.unselectRow(2);
    },
    onFiltered(filteredItems) {
      if (this.currentPage != 1) {
        this.currentPage = 1;
      } else {
        this.loading = true;
        this.listar();
      }
    },
    listar() {
      var url =
        "/admin/usuarios?page=" + this.currentPage + "&buscar=" + this.filter;
      axiosIns
        .get(url)
        .then((res) => {
          this.items = res.data.data;
          this.totalRows = res.data.total;
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    actualizarLista() {
      this.loading = true;
      this.listar();
      this.accion = "";
      this.item = [];
    },
    abriComponente(item) {
      this.accion = "actualizar";
      this.item = item;
    },
    cerrarComponente() {
      this.accion = "";
      this.item = [];
    },
    confirmar_eliminar(id) {
      axiosIns
        .get("/admin/usuarios/" + id)
        .then((res) => {
          let respuesta = res.data;
          if (respuesta) {
            this.$swal({
              icon: "warning",
              title: "Advertencia!",
              timer: 2000,
              text: "No se puede eliminar.",
              confirmButtonText: "Aceptar",
              customClass: {
                confirmButton: "btn btn-success",
              },
            });
          } else {
            this.$swal({
              title: "Esta seguro de eliminar ?",
              text: "¡No podrás revertir esto!",
              icon: "warning",
              showCancelButton: true,
              confirmButtonText: "Eliminar",
              cancelButtonText: "Cancelar",
              customClass: {
                confirmButton: "btn btn-primary",
                cancelButton: "btn btn-outline-danger ml-1",
              },
              buttonsStyling: false,
            }).then((result) => {
              if (result.value) {
                this.eliminar(id);
              }
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    eliminar(id) {
      axiosIns
        .delete("/admin/usuarios/" + id)
        .then((res) => {
          this.$bvToast.toast("Exito", {
            title: "Se elimino correctamente",
            variant: "primary",
            solid: false,
          });
          this.actualizarLista();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    confirmar_inactivar(id) {
      this.$swal({
        title: "Advertencia",
        text: "Confirme que esta seguro de inactivar al usuario",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Confirmar",
        cancelButtonText: "Cancelar",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.inactivar(id);
        }
      });
    },
    inactivar(id) {
      axiosIns
        .put("/admin/usuarios/inactivar/" + id)
        .then((res) => {
          this.$bvToast.toast("Exito", {
            title: "Se inactivo correctamente",
            variant: "primary",
            solid: false,
          });
          this.actualizarLista();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    confirmar_password_default(id) {
      this.password = "fiscalia123";
      this.$swal({
        title: "Advertencia",
        text: "Confirme que esta seguro de restablecer la contraseña del usuario",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Confirmar",
        cancelButtonText: "Cancelar",
        input: "text",
        inputLabel: "Contraseña",
        inputValue: "fiscalia123",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
        inputValidator: (value) => {
          if (!value) {
            return "El campo contraseña es obligatorio.";
          } else if (value.length < 8) {
            return "El campo contraseña debe contener al menos 8 caracteres.";
          }
        },
      }).then((result) => {
        if (result.value) {
          this.password = result.value;
          this.password_default(id);
        }
      });
    },
    confirmar_toogle_2F() {
      this.$swal({
        title: "Advertencia",
        text: "Confirme que esta seguro de " + this.titulo2f,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Confirmar",
        cancelButtonText: "Cancelar",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        //console.log(result);
        if (result.value) {
          this.toogle2f();
        }
      });
    },
    toogle2f() {
      axiosIns
        .put("/toogle2f")
        .then((res) => {
          this.autenticacion_2F = res.data.data.valor;
          this.$bvToast.toast("Exito", {
            title: res.data.msg,
            variant: "primary",
            solid: false,
          });
          this.actualizarLista();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    password_default(id) {
      axiosIns
        .put("/admin/usuarios/password-default/" + id, {
          password: this.password,
        })
        .then((res) => {
          this.$bvToast.toast("Exito", {
            title: "Se restablecio la contraseña correctamente",
            variant: "primary",
            solid: false,
          });
          this.actualizarLista();
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss">
</style>