<template>
  <b-card title="Usuarios">
    <b-tabs>
      <b-tab lazy title="Usuarios Activos">
        <UsuariosActivos/>
      </b-tab>
      <b-tab lazy title="Usuarios Inactivos">
        <UsuariosInactivos/>
      </b-tab>
    </b-tabs>
  </b-card>
</template>

<script>
import axiosIns from "@/libs/axios";
import { BTabs, BTab, BCardText, BCard } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { $themeColors } from "@themeConfig";
import UsuariosActivos from "@/views/configuracion/usuarios/UsuariosActivos.vue";
import UsuariosInactivos from "@/views/configuracion/usuarios/UsuariosInactivos.vue";

export default {
  components: {
    BTabs,
    BCardText,
    BTab,
    BCard,
    UsuariosActivos,
    UsuariosInactivos,
  },
  directives: {
    Ripple,
  },
  data() {
    return {

    };
  },
  created() {},
  watch: {
  },
  methods: {
    
  },
};
</script>
<style lang="scss"></style>
