<template>
  <b-card no-body title="Usuarios Inactivos">
    <b-row>
      <b-col
        md="6"
        class="mb-1"
      >
        <b-button @click="actualizarLista()" variant="secondary" class="btn-icon mr-1" >
          <feather-icon
            icon="RotateCcwIcon"
          />
        </b-button>

      </b-col>
      <b-col
        md="6"
        class="mb-1"
      >
        <b-form-group
          label=""
          label-align-sm="right"
          label-for="filterInput"
          class="mb-0"
        >
          <b-input-group>
            <b-form-input
              id="filterInput"
              v-model="filter"
              type="search"
              @keyup.enter="onFiltered()"
              placeholder="Buscar.."
            />
          </b-input-group>
        </b-form-group>
      </b-col>

      <b-col cols="12">
        <b-overlay
          :show="loading"
        >
          <b-table class="sm" responsive="sm" 
              ref="selectableTable"
              selectable
              select-mode="single" 
              hover
              :small="true"
              :items="items" 
              :fields="fields"
              @row-selected="onRowSelected"
              show-empty
              empty-text="No se encontraron registros coincidentes"
              >
              <template #cell(selected)="{ rowSelected }">
                  <template v-if="rowSelected">
                    <i class="feather icon-disc primary" />
                  </template>

                  <template v-else>
                    <i class="feather icon-circle" />
                  </template>
              </template>

              <template #cell(roles)="data">
                <b-badge v-if="data.value[0]" variant="primary">
                  {{ data.value[0].name }}
                </b-badge>
              </template>

              <template #cell(estado)="data">
                <b-badge :variant="estado[1][data.value]">
                  {{ estado[0][data.value] }}
                </b-badge>
              </template>

              <template #cell(accion)="data">
                <div class="text-nowrap" v-if="data.item.id!=1">
                    <b-button
                        v-b-modal.modal-usuario @click="abriComponente(data.item)"
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        variant="outline-primary"
                        v-b-tooltip.hover.bottom="'Editar'"
                        class="btn-icon mr-1"
                        size="sm"
                    >
                        <feather-icon icon="EditIcon" />
                    </b-button>

                    <b-button
                        @click="confirmar_activar(data.item.id)"
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        variant="outline-primary"
                        v-b-tooltip.hover.bottom="'Activar usuario'"
                        class="btn-icon mr-1"
                        size="sm"
                    >
                        <feather-icon icon="UserCheckIcon" />
                    </b-button>

                     <b-button
                        @click="confirmar_password_default(data.item.id)"
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        variant="outline-danger"
                        v-b-tooltip.hover.bottom="'Restablecer contraseña'"
                        class="btn-icon"
                        size="sm"
                    >
                        <feather-icon icon="LockIcon" />
                    </b-button>
                </div>
              </template>
    
              <template #cell()="data">
                <span class="text-nowrap">{{ data.value }}</span>
              </template>

          </b-table>
        </b-overlay>
      </b-col>
      <b-col cols="12">
          <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="right"
          size="sm"
          class="my-0"
        />
      </b-col>
    </b-row>
    <UsuarioNuevoEditar :accion="accion" :item="item" @cerrarComponente="cerrarComponente" @actualizarLista="actualizarLista"></UsuarioNuevoEditar>
  </b-card>
</template>

<script>
import axiosIns from '@/libs/axios'
import { BCard, BCardText, BTable, BBadge, BRow, BCol, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BInputGroupAppend, BButton, BDropdown, BDropdownItem, BOverlay, VBTooltip } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import UsuarioNuevoEditar from '@/views/configuracion/usuarios/UsuarioNuevoEditar.vue';
//import axios from 'axios'
export default {
    components: {
        BCard,
        BCardText,
        BTable,
        BBadge,
        BRow,
        BCol,
        BFormGroup,
        BFormSelect,
        BPagination,
        BInputGroup,
        BFormInput,
        BInputGroupAppend,
        BButton,
        BDropdown,
        BDropdownItem,
        UsuarioNuevoEditar,
        BOverlay
    },
    directives: {
      Ripple,
      'b-tooltip': VBTooltip,
    },
    data(){
        return{
              items:[],
              fields: [
                    { key: 'name', label: 'Nombre completo', sortable: false },
                    { key: 'email', label: 'Email', sortable: false },
                    { key: 'roles', label: 'Rol', sortable: false },
                    { key: 'estado', label: 'Estado', sortable: false },
                    'accion',
                ],
              selected: [],
              filter: '',
              filterOn: [],
              perPage: 10,
              pageOptions: [10, 25, 50],
              totalRows: 1,
              currentPage: 1,
              accion:'',
              loading:false,
              item:[],
              estado: [{
                1: 'Activo', 0: 'Inactivo',
              },
              {
                1: 'light-primary', 0: 'light-danger',
              }],
              password:"fiscalia123",
        }
    },
    created(){        
        this.loading=true       
        this.listar();
    },
    watch: {
      currentPage: function() {
        if (!this.loading) {
          this.loading = true;
          this.listar();
        }
      },
      filter: function(val) {
        if (val == "" || val.length == 0) {
          this.loading=true
          this.listar();
        }
      },
    },   
    methods: {
        onRowSelected(items) {
          this.selected = items
        },
        selectAllRows() {
          this.$refs.selectableTable.selectAllRows()
        },
        clearSelected() {
          this.$refs.selectableTable.clearSelected()
        },
        selectThirdRow() {
          this.$refs.selectableTable.selectRow(2)
        },
        unselectThirdRow() {
          this.$refs.selectableTable.unselectRow(2)
        },
        onFiltered(filteredItems) {
          if (this.currentPage!=1) {
            this.currentPage=1;
          }else{
            this.loading=true
            this.listar();
          }
        },
        listar(){
            var url= '/admin/usuarios/ver/inactivos?page='+this.currentPage + "&buscar=" + this.filter;
            axiosIns.get(url)
            .then(res => {
                this.items=res.data.data;
                this.totalRows = res.data.total
                this.loading=false
            })
            .catch(err =>{
                console.log(err);
            });
        },
        actualizarLista(){
          this.loading=true;
          this.listar();
          this.accion='';
          this.item=[];
        },
        abriComponente(item){
          this.accion="actualizar";
          this.item=item;
        },
        cerrarComponente(){
          this.accion='';
          this.item=[];
        },
        confirmar_activar(id) {
            this.$swal({
                title: "Advertencia",
                text: "Confirme que esta seguro de activar al usuario",
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Confirmar",
                cancelButtonText: "Cancelar",
                customClass: {
                confirmButton: "btn btn-primary",
                cancelButton: "btn btn-outline-danger ml-1",
                },
                buttonsStyling: false,
            }).then((result) => {
                if (result.value) {
                this.activar(id);
                }
            });
        },
        activar(id) {
         axiosIns.put('/admin/usuarios/activar/'+id)
          .then(res => {
              this.$bvToast.toast('Exito', {
                title: 'Se activo correctamente',
                variant:'primary',
                solid: false,
              })
              this.actualizarLista();
          }) 
          .catch(err =>{
              console.log(err);
          });
        },
        confirmar_password_default(id) {
          this.password='fiscalia123';
          this.$swal({
            title: "Advertencia",
            text: "Confirme que esta seguro de restablecer la contraseña del usuario",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Confirmar",
            cancelButtonText: "Cancelar",
            input: 'text',
            inputLabel: 'Contraseña',
            inputValue:'fiscalia123',
            customClass: {
              confirmButton: "btn btn-primary",
              cancelButton: "btn btn-outline-danger ml-1",
            },
            buttonsStyling: false,
            inputValidator: (value) => {
              if (!value) {
                return 'El campo contraseña es obligatorio.'
              }else if(value.length<8){
                return 'El campo contraseña debe contener al menos 8 caracteres.'
              }
            }
          }).then((result) => {
            if (result.value) {
              this.password=result.value;
              this.password_default(id);
            }
          });
        },
        password_default(id) {
          axiosIns.put("/admin/usuarios/password-default/" + id,{
            password: this.password,
          })
          .then(res => {
              this.$bvToast.toast('Exito', {
                title: 'Se restablecio la contraseña correctamente',
                variant:'primary',
                solid: false,
              })
              this.actualizarLista();
          }) 
          .catch(err =>{
              console.log(err);
          });
        },
    },
}
</script>

<style lang="scss">

</style>